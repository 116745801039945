import React, { useState } from 'react'
import { T } from '../../typo'
import { s, sHtml, globals, colors } from '../../style'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { sTextInput } from '../../forms/FormItems/FormInputs'
import { TextField, Grid } from '@material-ui/core'
import { string_to_slug } from '../../helpers'
import Img from 'gatsby-image'
import { ButtonMain } from '../../buttons'

import {
  sCategory,
  sCategories,
  sBlogWrapper,
  sBlog,
  sBlogTheme,
  sImage,
  sTitle,
  sDescription,
  sContainer,
  sIntro,
} from '../BlogsSearch/BlogsSearch'

const CareerSearch = ({ theme, pageData }) => {
  const language = pageData.language
  const [searchInput, setSearchInput] = useState('')
  const [selectedCategory, setSelectedCategory] = useState(null)
  const onValueChange = (event) => {
    event.preventDefault()
    setSearchInput(event.target.value)
    setSelectedCategory(null)
  }
  const results = useStaticQuery(graphql`
    {
      careers: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/careers/" } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              url
              language
              career_slug
              description
              career_category
              date
              image {
                name
                publicURL
                childImageSharp {
                  fluid(
                    maxWidth: 400
                    quality: 100
                    toFormat: JPG
                    jpegProgressive: true
                  ) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
      categories: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/categories-career/" } }
        sort: { fields: frontmatter___order, order: ASC }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
            }
          }
        }
      }
      dimagqimage: file(
        absolutePath: { regex: "/content/images/dimaqemployer.png/" }
      ) {
        childImageSharp {
          fluid(
            maxWidth: 400
            quality: 100
            toFormat: JPG
            jpegProgressive: true
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const careers = results.careers.edges.map(({ node }) => node.frontmatter)

  const categories = results.categories.edges.map(
    ({ node }) => node.frontmatter
  )
  const careersExisting = careers
    .filter((career) => career.title && career.language === language)
    .filter((career) =>
      selectedCategory ? career.career_category === selectedCategory : true
    )
    .filter((career) =>
      searchInput.length > 0
        ? career.title.toLowerCase().includes(searchInput.toLowerCase()) ||
          career.career_category
            ?.toLowerCase()
            .includes(searchInput.toLowerCase())
        : true
    )

  return (
    <div css={sContainer}>
      <div css={sIntro}>
        <T
          d={64}
          m={48}
          bold
          variant="h1"
          extraCss={{
            marginBottom: '0.5rem',
          }}>
          {pageData.title}
        </T>
        {pageData.subtitle && <T>{pageData.subtitle}</T>}
        <T>{pageData.description}</T>
      </div>
      <div
        css={[
          sHtml,
          sBlogContainer,
          {
            backgroundColor: colors[theme].container,
            li: { marginBottom: '1rem' },
            ul: { paddingBottom: '1rem' },
            span: { opacity: 0.6, fontSize: '0.875rem' },
            p: { lineHeight: '2em' },
          },
        ]}>
        <p>
          <strong>Reklama Dla Biznesu</strong> prowadzi stałą rekrutację na
          wymienione poniżej stanowiska pracy. Jeśli marketing to kierunek, w
          którym zawodowo się realizujesz,{' '}
          <strong>
            zapraszamy Cię do wzięcia udziału w procesie rekrutacji!
          </strong>
        </p>
        <p>Rekrutacja składa się z 5 etapów:</p>
        <ul>
          <li>
            <strong>ETAP 1</strong> - Rozpatrywanie zgłoszeń w formie CV i
            listów motywacyjnych.
          </li>
          <li>
            <strong>ETAP 2</strong> - Zaproszenie wybranych kandydatów na
            rozmowę wstępną online.
          </li>
          <li>
            <strong>ETAP 3</strong> - Zaproszenie na dzień próbny. Wykonanie
            zadań i przedstawienie wyniku pracy w formie prezentacji. Rozmowa na
            temat doświadczeń oraz weryfikacja mocnych stron kandydata.
          </li>
          <li>
            <strong>ETAP 4</strong> - Tydzień próbny. Zakończony złożeniem
            oferty pracy lub zamknięciem procesu rekrutacji dla danego
            kandydata.
          </li>
          <li>
            <strong>ETAP 5</strong> - Podpisanie umowy i właściwy onboarding dla
            nowego pracownika.
          </li>
        </ul>
        <p>
          <span>
            Poniżej znajdują się wszystkie stanowiska, na które obecnie
            prowadzimy rekrutacje. Zapraszamy do wzięcia udziału.
          </span>
        </p>
      </div>
      <div
        css={[
          sHtml,
          sBlogContainer,
          {
            backgroundColor: colors[theme].container,
            position: 'relative',
            p: {
              lineHeight: '2em',
              fontSize: '0.875rem',
              [s.lg]: { maxWidth: '75%' },
            },
          },
        ]}>
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            marginBottom: '2rem',
            [s.xs]: {
              flexDirection: 'column',
            },
          }}>
          <T d={48} m={32} bold variant="h2">
            DIMAQ Employer
          </T>
          {results?.dimagqimage?.childImageSharp?.fluid && (
            <div
              css={{
                width: 190,
                height: 64,
                position: 'relative',
                top: 0,
                left: 0,
              }}>
              <Img
                fluid={results.dimagqimage.childImageSharp.fluid}
                alt={'DIMAQ Employer'}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
                imgStyle={{ margin: 0 }}
              />
            </div>
          )}
        </div>

        <p>
          Jeśli posiadasz certyfikat <strong>DIMAQ</strong> na poziomie{' '}
          <strong>Basic</strong> lub <strong>Professional</strong>, prześlij do
          nas aplikację, dołączając w załączniku swój certyfikat. Jest to
          potwierdzenie Twoich kwalifikacji, które znacznie ułatwi i skróci
          proces rekrutacji.
        </p>
        <ButtonMain link={'https://dimaq.pl/dimaq-employer/'} theme="light">
          Więcej o DIMAQ
        </ButtonMain>
      </div>

      <div
        css={{
          [s.xs]: { margin: '3rem 1rem 0rem' },
          [s.sm_only]: { margin: '2rem 2rem 0rem' },
          paddingBottom: '1rem',
        }}>
        <TextField
          onChange={onValueChange}
          name="search"
          value={searchInput}
          variant="filled"
          label="Szukaj Ogłoszenia"
          css={[sTextInput(theme)]}
          fullWidth
        />
      </div>
      <div css={sCategories}>
        <div
          onClick={() => setSelectedCategory(null)}
          onKeyPress={() => setSelectedCategory(null)}
          tabIndex={0}
          role="button"
          css={[
            sCategory(theme),
            selectedCategory === null && sCategory(theme).active,
          ]}>
          Wszystkie
        </div>
        {categories
          .sort((a, b) => a.order - b.order)
          .map((category, id) => (
            <div
              key={id}
              onClick={() => setSelectedCategory(category.title)}
              onKeyPress={() => setSelectedCategory(category.title)}
              tabIndex={0}
              role="button"
              css={[
                sCategory(theme),
                selectedCategory === category.title && sCategory(theme).active,
              ]}>
              {category.title}
            </div>
          ))}
      </div>

      <Grid container>
        {careersExisting.map((career, id) => (
          <Grid xs={12} sm={6} md={4} item css={sBlogWrapper} key={id}>
            <Link
              to={`/${
                career.career_slug
                  ? career.career_slug
                  : string_to_slug(career.title)
              }`}
              key={id}
              css={[
                sBlog,
                sBlogTheme(theme),
                // !career.career_slug && { filter: 'grayscale(1)', opacity: 0.3 },
              ]}>
              <div css={sImage}>
                {career.image?.childImageSharp?.fluid && (
                  <Img
                    style={sImage.image}
                    fluid={career.image.childImageSharp.fluid}
                  />
                )}
              </div>
              <div css={sTitle}>{career.title}</div>
              <div css={sDescription}>{career.description}</div>
            </Link>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

const sBlogContainer = {
  [s.sm_down]: {
    boxShadow: globals.shadows.sections.mobile,
    borderRadius: globals.roundness.sections.mobile,
    marginBottom: globals.spacing.inside.mobile,
  },
  [s.md]: {
    boxShadow: '0px 12px 32px rgb(0 0 0 / 8%)',
    borderRadius: globals.roundness.sections.desktop,
    marginBottom: globals.spacing.inside.desktop,
  },
}

export default CareerSearch
